<template>
    <div class="layout">
        <!-- Navigation Bar -->
        <header v-if="showNavBar" style="height: 120px;">
            <Navbar />
        </header>

        <!-- Hero Section -->
        <header2 v-if="showHeader" style="margin-top: 0;">
            <HeroPage />
        </header2>

        <!-- Features Section -->
        <main v-if="showMain1">
            <FeaturesPage />
        </main>

        <!-- Main Content -->
        <main>
            <router-view></router-view>
        </main>

        <!-- Footer Section -->
        <footer>
            <div class="footer-contact-us" @click="toggleDropdown">
                Contact Us

                <!-- Drop-up Contact Menu -->
                <div v-show="isOpen" class="dropup-menu">
                    <ul class="ContactUs__menu">
                        <li class="ContactUs__menu--item top">
                            <a href="/contact-us" class="ContactUs__menu--link violet" rel="nofollow noopener external">
                                Send us an Email
                                <i class="bi bi-chat-dots"></i>
                            </a>
                        </li>
                        <li class="ContactUs__menu--item">
                            <a href="" class="ContactUs__menu--link violet" rel="nofollow noopener external">
                                Messenger
                                <i class="bi bi-chat-dots"></i>
                            </a>
                        </li>
                        <li class="ContactUs__menu--item whatsapp" @click="selectedOption = 'whatsapp'">
                            <WhatsAppButton phoneNumber="254723457189" message="Hello, I need some help!" />
                        </li>
                    </ul>
                </div>

                {{ selectedOption }}

                <!-- WhatsApp Embed -->
                <WhatsAppEmbed phoneNumber="254723457189" message="Hello, I need some help!"
                    v-if="selectedOption === 'whatsapp'" />
            </div>

            <!-- Footer Copyright -->
            <p>&copy; 2023 Procus Limited</p>
        </footer>
    </div>
</template>

<script>
    import { ref, watch, onMounted, onUnmounted } from 'vue';
    import { useRoute } from 'vue-router';
    import HeroPage from '@/components/HeroPage.vue';
    import Navbar from '@/components/NavBar.vue';
    import FeaturesPage from '@/views/Features/FeaturesPage.vue';
    import WhatsAppButton from '@/views/profile/WhatsAppButton.vue';
    import WhatsAppEmbed from '@/views/profile/WhatsAppEmbed.vue';

    export default {
        name: 'LayoutFile',
        components: {
            HeroPage,
            Navbar,
            FeaturesPage,
            WhatsAppButton,
            WhatsAppEmbed,
        },

        setup() {
            const route = useRoute();
            const showHeader = ref(false);
            const showNavBar = ref(true);
            const showMain1 = ref(false);
            const selectedOption = ref('');
            const isOpen = ref(false);

            watch(
                () => route.meta,
                (meta) => {
                    showHeader.value = meta.showHeader || false;
                    showNavBar.value = meta.showNavBar || false;
                    showMain1.value = meta.showMain1 || false;
                },
                { immediate: true }
            );

            const toggleDropdown = () => {
                isOpen.value = !isOpen.value;
            };

            const handleClickOutside = (event) => {
                if (isOpen.value && !event.target.closest('.footer-contact-us')) {
                    isOpen.value = false;
                }
            };

            onMounted(() => {
                window.addEventListener('click', handleClickOutside);
            });

            onUnmounted(() => {
                window.removeEventListener('click', handleClickOutside);
            });

            return {
                showHeader,
                showNavBar,
                showMain1,
                isOpen,
                toggleDropdown,
                selectedOption,
            };
        },
    };
</script>

<style scoped>
    @import url('@/assets/styles/layout.css');
</style>
