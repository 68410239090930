<template>
    <div class="custom-modal" v-if="showModal">
        <div class="modal-content">
            <div class="modal-header">
                <slot name="header">
                    <span class="custom-header">Custom Header</span>
                </slot>
                <span class="close" @click="closeModal">&times;</span>
            </div>
            <div class="modal-body">
                <slot name="content">
                    <p class="custom-content">Custom Content</p>
                </slot>
            </div>
            <div class="modal-footer">
                <!-- <slot name="footer">
                    <button @click="closeModal" class="custom-footer">Close</button>
                </slot> -->
            </div>
        </div>
    </div>
</template>

<script>
import { ref, onMounted, watch } from 'vue';

export default {
    name: 'ModalView',
    props: {
        showModal: {
            type: Boolean,
            required: true,
        },
        onClose: {
            type: Function,
        },
    },
    setup(props) {
        const modalVisible = ref(props.showModal);

        watch(() => {
            modalVisible.value = props.showModal;
        });

        const closeModal = () => {
            modalVisible.value = false;
            if (props.onClose) {
                props.onClose();
            }
        };

        onMounted(() => {
            modalVisible.value = props.showModal;
        });

        return {
            modalVisible,
            closeModal,
        };
    },
};
</script>

<style scoped>
    /* Modal Css  */

    .custom-modal {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        /* changed from center to flex-end */
        position: fixed;
        z-index: 9999;
        right: 20px;
        /* added right offset */
        top: 0;
        width: calc(100% - 40px);
        /* adjusted width to account for right offset */
        height: 100%;
        overflow: auto;
    }

    .custom-modal .modal-content {
        background-color: #fff;
        border-radius: 10px;
        width: 80%;
        box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
        animation: slideDown 0.4s ease-out;
        position: fixed;
        top: 100px;
        bottom: 50px;
        border-radius: 16px;
        /* Slightly larger border radius */
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
        /* Adjusted shadow */
        max-width: calc(100vw - 40px);
        /* Maximum width relative to viewport width */
        max-width: 500px;
        z-index: 1000;
        display: flex;
        flex-direction: column;
        align-items: center;
        /* Center content horizontally */
        max-height: 100vh;
        /* Maximum height relative to viewport height */
        overflow-y: auto;
        /* Enable vertical scrolling */
    }

    /* Example of styling the scrollbar */
    .custom-modal .modal-content::-webkit-scrollbar {
        width: 8px;
        /* Width of scrollbar */
    }

    .custom-modal .modal-content::-webkit-scrollbar-thumb {
        background-color: rgba(0, 0, 0, 0.2);
        /* Color of scrollbar thumb */
        border-radius: 4px;
        /* Rounded corners for thumb */
    }

    .custom-modal .modal-header,
    .custom-modal .modal-body,
    .custom-modal .modal-footer {
        padding: 10px;
    }

    .custom-modal .modal-body {
        width: calc(100% - 20px);
    }

    .custom-modal .modal-header {
        display: flex;
        justify-content: space-between;
        align-items: space-between;
        padding: 10px;
        background-color: var(--background-color);
        border-bottom: 1px solid var(--background-color);
        margin: 0;
        width: 100%;
        align-items: center;
        border-bottom: 1px solid #ddd;
    }

    .custom-modal .modal-header .custom-header {
        font-weight: bold;
    }

    .custom-modal .modal-header .close {
        font-weight: bold;
        color: #000;
        cursor: pointer;
        transition: color 0.3s;
        font-size: 24px;
    }

    .custom-modal .modal-header .close:hover {
        color: #000;
    }

    .custom-modal .modal-body .custom-content {
        margin-top: 10px;
    }

    .custom-modal .modal-footer {
        display: flex;
        justify-content: flex-end;
        border-top: 1px solid #ddd;
    }

    /* Animation for modal appearance */
    @keyframes slideDown {
        from {
            transform: translateY(-50px);
            opacity: 0;
        }

        to {
            transform: translateY(0);
            opacity: 1;
        }
    }

    /* Media Queries for Mobile Responsiveness */
    @media (max-width: 768px) {
        .custom-modal .modal-content {
            width: 90%;
            max-width: 400px;
        }

        .custom-modal .modal-header .custom-header {}

        .custom-modal .modal-body .custom-content {}
    }

    @media (max-width: 480px) {
        .custom-modal .modal-content {
            width: 95%;
            max-width: 300px;
        }

        .custom-modal .modal-header .custom-header {}

        .custom-modal .modal-body .custom-content {}
    }
</style>