<template>

  <div>
    <!-- Display a message based on the online status -->
    <div v-if="online">
      <LayoutFile />
    
    </div>
    <div v-else>You are offline. Please check your internet connection.</div>
  </div>

  <!-- <router-view/> -->
</template>

<script>
import LayoutFile from '@/components/LayoutFile.vue'
import { onMounted, onBeforeUnmount, ref } from 'vue';
export default {
  name: 'App',
  components: {
    LayoutFile
  },

  setup() {
    const online = ref(true); // Default to true assuming online until proven otherwise

    // Function to check online status
    const checkOnlineStatus = () => {
      // online.value = navigator.onLine;
      online.value =true
    };

    // Function to handle changes in online status
    const handleOnlineStatusChange = () => {
      online.value = navigator.onLine;
    };

    // Add event listeners when the component is mounted
    onMounted(() => {
      checkOnlineStatus();
      window.addEventListener('online', handleOnlineStatusChange);
      window.addEventListener('offline', handleOnlineStatusChange);
    });

    // Remove event listeners when the component is destroyed
    onBeforeUnmount(() => {
      window.removeEventListener('online', handleOnlineStatusChange);
      window.removeEventListener('offline', handleOnlineStatusChange);
    });

    return {
      online
    };
  }

}
</script>

<style>
  
</style>
