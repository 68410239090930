<template>

  <nav :class="{ scrolled: isScrolled }">
    <div class="menu-items">
      <div class="mobile-header">
        <div class="menu-icon" @click="openModal">
          <i class="bi bi-list"></i>
        </div>

        <!-- <div class="logo">
          <a href="/">
            <img src="../assets/BEVaC.png" alt="Logo" />
          </a>
        </div> -->
      </div>
      <div class="desktop-header">
        <div class="menu-icon" @click="toggleMenu">
          <i class="bi bi-list"></i>
        </div>

        <div class="logo">
          <a href="/">
            <img src="../assets/logos/paanguzo.png" alt="Logo" />
          </a>
        </div>
      </div>

      <div class="menu" :class="{ active: menubar }">
        <div>
          <!-- <router-link to="/">Home</router-link> -->
          <a href="/">Home</a>
        </div>

        <div class="dropdown" @mouseover="showDropdown" @mouseout="hideDropdown">
          <a href="/buy">Property</a>
        </div>

        <div class="dropdown">
          <a href="/materials">Materials</a>
        </div>

        <!-- <div class="dropdown">
          <a href="/transportation">Transport</a>
        </div> -->
        <!-- <div class="dropdown">
          <a href="/funding">Funding</a>
        </div> -->

        <div class="dropdown">
          <a href="/jobsearch">Jobs</a>
        </div>

        <!-- <div class="dropdown">
          <a href="/our-services">Our Services</a>
        </div> -->
        <div class="dropdown">
          <a href="/materialsearch">Hardware Shop</a>
        </div>



        <div class="hide">
          <a href="#">Company</a>
        </div>


      </div>

    </div>


    <div class="profile-area">

      <DropDown v-if="authState.isLoggedIn" class="dropdown profile">
        <!-- Trigger button slot -->
        <template v-slot:trigger="{ toggleDropdown }">
          <div class="nav-profile-container" @click="toggleDropdown">
            <!-- Profile Image -->
            <img v-if="userProfile?.profile_picture" :src="userProfile.profile_picture" class="profile-picture"
              id="nav-profile" />
            <img v-else src="../assets/avatars/default_profile_preview.jpeg" class="profile-picture" id="nav-profile" />

            <!-- User's Name -->
            <p class="user-name">
              {{ user.user.first_name }} {{ user.user.last_name }}
            </p>

            <!-- Dropdown Icon -->
            <i class="dropdown-icon"></i>
          </div>
        </template>

        <!-- Dropdown content slot -->
        <template v-slot:content>
          <ul class="dropdown-content">
            <li>
              <a href="/myprofile?value=my-profile" v-if="authState.isLoggedIn">Profile</a>
            </li>
            <li>
              <a href="/myprofile?value=customer-account">Customer Account</a>
            </li>
            <li><a href="/supplier">Supplier Account</a></li>
            <li>
              <a :href="'/myprofile?value=' + encodeURIComponent('point-purchase')
                ">
                My Account
              </a>
            </li>
            <li>
              <a :href="'/myprofile?value=' + encodeURIComponent('list-property')
                ">
                List a Property
              </a>
            </li>
            <li>
              <a :href="'/myprofile?value=' + encodeURIComponent('hire-manpower')
                ">
                Post a Job
              </a>
            </li>
            <li>
              <a :href="'/myprofile?value=' + encodeURIComponent('project-proposal')
                ">
                Invest in a Project
              </a>
            </li>
            <li>
              <a href="#" @click="logout" v-if="authState.isLoggedIn">Logout</a>
            </li>
          </ul>
        </template>
      </DropDown>

    </div>





    <div class="signup-login-button" v-if="!authState.isLoggedIn">
      <a @click="$router.push('/signuplogin')">login/signup</a>
    </div>

  </nav>


  <Modal :showModal="showModal" @close="showModal = false">
    <!-- Custom Header -->
    <template v-slot:header>
      <!-- <h3>{{ modalHeader }}</h3> -->
      <div class="logo-mobile">
        <a href="/">
          <img src="../assets/logos/paanguzo.png" alt="Logo" />
        </a>
      </div>
    </template>

    <!-- Custom Content -->
    <template v-slot:content>
      <div class="mobile" :class="{ active: menubar }">
        <div>
          <a href="/">Home</a>
        </div>

        <div class="dropdown" @mouseover="showDropdown" @mouseout="hideDropdown">
          <a href="/buy">Property</a>
        </div>

        <div class="dropdown">
          <a href="/materialsearch">Specifications</a>
        </div>

        <div class="dropdown">
          <a href="/funding">Funding</a>
        </div>

        <div class="dropdown">
          <a href="/materialsearch">Hardware Shop</a>
        </div>

        <div class="dropdown">
          <a href="/jobsearch">Jobs</a>
        </div>

        <!-- <div class="dropdown">
          <a href="#">Company</a>
        </div> -->

        <DropDown v-if="isLoggedIn" class="dropdown hide">
          <!-- Trigger button slot -->
          <template v-slot:trigger="{ toggleDropdown }">
            <div>
              <img v-if="userProfile?.profile_picture" :src="userProfile.profile_picture"
                style="width: 30px; height: 30px" @click="toggleDropdown" id="nav-profile" />
              <img v-else src="../assets/avatars/default_profile_preview.jpeg" style="width: 30px; height: 30px"
                @click="toggleDropdown" id="nav-profile" />
            </div>
          </template>

          <!-- Dropdown content slot -->
          <template v-slot:content>
            <ul class="profile-menu">
              <li>
                <a href="/myprofile?value=my-profile" v-if="isLoggedIn">Profile</a>
              </li>

              <li>
                <a href="/myprofile?value=customer-account">Customer Account</a>
              </li>
              <li><a href="/supplier">Supplier Account </a></li>
              <li>
                <a :href="
                    '/myprofile?value=' + encodeURIComponent('point-purchase')
                  ">Purchase Points</a>
              </li>
              <li>
                <a href="#" @click="logout" v-if="isLoggedIn">Logout</a>
              </li>
            </ul>
          </template>
        </DropDown>
      </div>
    </template>

    <!-- Custom Footer -->
    <template v-slot:footer>
      <button @click="closeModal" class="custom-footer">Custom Close</button>
    </template>
  </Modal>
</template>

<script>
import { ref, onMounted, computed, onBeforeUnmount } from "vue";
import axios from "@/utils/axios.js";
import DropDown from "@/components/DropDown.vue";
import Modal from "@/components/Modal.vue";
import {
  authState,
  userId,
  user,
  checkLoggedIn,
} from "@/utils/CheckLoginInfo";
// import { useRouter } from 'vue-router';

export default {
  name: "NavBar",

  components: {
    DropDown,
    Modal,
  },
  data() {
    return {
      isDropdownVisible: false,
      menubar: false,
    };
  },
  methods: {
    showDropdown() {
      this.isDropdownVisible = true;
    },
    hideDropdown() {
      this.isDropdownVisible = false;
    },
    toggleMenu() {
      this.menubar = !this.menubar;
    },
    closeMenu() {
      this.menubar = false;
    },
  },
  setup() {
    const userprofiledata = ref([]);
    const userProfile = ref([]);

    const getUserProfile = async () => {
      const response = await axios.get("/userprofile/my-profile/", {
        params: {
          user: userId.value,
        }
      });
      userProfile.value = response.data.results[0];

    };

    const isScrolled = ref(false);
    const showLoader = ref(false);

    const handleScroll = () => {
      isScrolled.value = window.scrollY > 0;
    };

    onMounted(() => {
      window.addEventListener("scroll", handleScroll);
    });

    onBeforeUnmount(() => {
      window.removeEventListener("scroll", handleScroll);
    });

    const menuItems = ref([
      { label: "Home", path: "/" },
      { label: "Property", path: "/Buy" },
      { label: "Building & Constructions", path: "/materials" },
      { label: "Funding", path: "/funding" },
      { label: "Jobs", path: "/jobs" },
      { label: "Developers", path: "/careers" },
      { label: "FAQ", path: "/pricing" },
      {
        label: "Company",
        path: "#",
        subMenus: [
          {
            label: "Profile",
            path: "/myprofile?value=my-profile",
            isLoggedIn: true,
          },
          {
            label: "Customer Account",
            path: "/myprofile?value=customer-account",
            isLoggedIn: true,
          },
          { label: "Supplier Account", path: "/supplier", isLoggedIn: true },
          {
            label: "Purchase Points",
            path: "/myprofile?value=point-purchase",
            isLoggedIn: true,
          },
          { label: "Logout", path: "#", isLoggedIn: true },
        ],
      },
    ]);

    const NavBar = ref([]);
    // const isLoggedIn = ref(null);
    // const router = useRouter();

    const showModal = ref(false);
    const modalHeader = ref("Adding a New Listing");

    const openModal = () => {
      showModal.value = true;
    };

    const closeModal = () => {
      showModal.value = false;
    };

    const toggleAccordion = (index) => {
      menuItems.value.forEach((menuItem, i) => {
        if (i === index) {
          menuItem.expanded = !menuItem.expanded;
        } else {
          menuItem.expanded = false;
        }
      });
    };

 

    const filteredSubItems = (parentId) => {
      return computed(() =>
        NavBar.value.filter((item) => item.id === parentId)
      );
    };

    const logout = () => {
      localStorage.removeItem("webToken");
      localStorage.clear();
      window.location.href = "/";
    };

    // const updateNavBar = async () => {
    //     const response = await axios.get('/navbar');
    //     NavBar.value = response.data;
    //     const filteredResponse = NavBar.value.filter(item => item.parent_item === null);
    //     NavBar.value = filteredResponse;
    //     // NavBar.value = response.data;
    // }

    // get urllocation
    const url = window.location.pathname;
    console.log(url);

    // Define your logout function

    onMounted(() => {
      // updateNavBar();
      checkLoggedIn();
      getUserProfile();
    });

    return {
      NavBar,
      // updateNavBar,
      filteredSubItems,
      logout,
      userprofiledata,
      userProfile,
      showModal,
      modalHeader,
      openModal,
      closeModal,

      menuItems,

      url,

      toggleAccordion,
      isScrolled,

      showLoader,
      authState,
      userId,
      user,
      checkLoggedIn,
      getUserProfile,
    };
  },
};
</script>
<style>

@import url('@/assets/styles/navbar.css')

</style>
