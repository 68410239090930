import heroImage from "../../assets/heros/paanguzohero.png";
import constructionImage from "../../assets/heros/Construction-min.png";
import materialsImage from "../../assets/heros/bevac_materials_hero-min.png";
import fundingImage from "../../assets/heros/bevac_funding_hero-min.png";
import propertyImage from "../../assets/heros/bevac_property_hero-min.png";
import jobsImage from "../../assets/heros/bevac_jobs_hero-min.png";

export const heroData = [
  {
    title: "Build Smarter, Together.",
    subTitle: "The all-in-one platform for a connected Built Environment.",
    callToAction: "Connect & Build Smarter!",
    imageUrl: heroImage, // Use a descriptive variable name for image URL
    altText: "Built Environment Hero Image",
    route: "/",
  },
  {
    title: "Find Your Perfect Property. Faster.",
    subTitle: "Our advanced search engine makes property hunting a breeze.",
    callToAction: "Explore Properties Now!",
    imageUrl: propertyImage,
    altText: "Property Image",
    route: "/property",
  },
  {
    title: "Efficient Construction Solutions.",
    subTitle: "Unlock faster and smarter construction processes.",
    callToAction: "Discover Construction Solutions!",
    imageUrl: constructionImage,
    altText: "Construction Image",
    route: "/construction",
  },
  {
    title: "Streamlined Materials Sourcing.",
    subTitle: "Source high-quality materials hassle-free.",
    callToAction: "Explore Materials Sourcing!",
    imageUrl: materialsImage,
    altText: "Materials Image",
    route: "/materials",
  },
  {
    title: "Secure Funding for Your Projects.",
    subTitle: "Access funding options tailored to your needs.",
    callToAction: "Get Funding Solutions!",
    imageUrl: fundingImage,
    altText: "Funding Image",
    route: "/funding",
  },
  {
    title: "Explore Exciting Job Opportunities.",
    subTitle:
      "Join our team and contribute to innovation in the Built Environment.",
    callToAction: "Browse Job Openings!",
    imageUrl: jobsImage,
    altText: "Jobs Image",
    route: "/jobs",
  },
  {
    title: "Our Services.",
    subTitle: "See what else we can do for you.",
    callToAction: "Learn More About Us!",
    imageUrl: jobsImage,
    altText: "Our Service",
    route: "/our-services",
  },
  {
    title: "Transport With Ease",
    subTitle: "Your seamless solution for deliveries and logistics management",
    callToAction: "Book Now!",
    imageUrl: jobsImage,
    altText: "Transportation",
    route: "/transportation",
  },
];
