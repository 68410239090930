<template>
    <div class="faq-list">
        <h3>Frequently Asked Questions</h3>
        <p>Find answers to some of the most commonly asked questions below. Click on a question to see the answer.</p>
        <div v-for="(faq, index) in faqData" :key="index" class="faq-item">
            <div class="faq-question" @click="toggleAccordion(index)">
                <span>{{ faq.question }}</span>
                <span :class="{ 'rotate-arrow': activeAccordion === index }" class="arrow">&#9658;</span>
            </div>
            <div v-if="activeAccordion === index" class="faq-answer">{{ faq.answer }}</div>
        </div>
    </div>
</template>

<script>
import { ref } from 'vue';
import { faqData } from '@/utils/Faq/Faq'
export default {
    setup() {
        const activeAccordion = ref(null);

        const toggleAccordion = (index) => {
            activeAccordion.value = activeAccordion.value === index ? null : index;
        };

        return {
            faqData,
            activeAccordion,
            toggleAccordion,
        };
    },
};
</script>

<style scoped>
@import url('@/assets/styles/faq.css');
</style>
