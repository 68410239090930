<template>
    <div class="DropDown" ref="dropdown">
        <!-- Dropdown Trigger -->
        <slot name="trigger" :toggleDropdown="toggleDropdown"></slot>

        <!-- Dropdown Content -->
        <transition name="fade">
            <div v-show="isOpen" class="dropdown-content" @click.stop>
                <slot name="content"></slot>
            </div>
        </transition>
    </div>
</template>

<script>
    import { ref, onMounted, onBeforeUnmount } from "vue";

    export default {
        name: "DropDown",
        setup() {
            const isOpen = ref(false);
            const dropdown = ref(null);

            const toggleDropdown = (event) => {
                console.log("Dropdown toggled"); // ✅ Debugging step
                event.stopPropagation(); // ✅ Prevent closing immediately
                isOpen.value = !isOpen.value;
            };

            const handleClickOutside = (event) => {
                if (dropdown.value && !dropdown.value.contains(event.target)) {
                    console.log("Click outside detected, closing dropdown"); // ✅ Debugging step
                    isOpen.value = false;
                }
            };

            onMounted(() => {
                document.addEventListener("click", handleClickOutside);
            });

            onBeforeUnmount(() => {
                document.removeEventListener("click", handleClickOutside);
            });

            return {
                isOpen,
                toggleDropdown,
                dropdown,
            };
        },
    };
</script>

<style scoped>
    .DropDown {
        position: relative;
        display: inline-block;
    }

    /* Dropdown Content */
    .dropdown-content {
        position: absolute;
        top: 100%;
        left: 0;
        background-color: white;
        box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
        border-radius: 4px;
        padding: 10px 0;
        margin-top: 8px;
        z-index: 1000;
        min-width: 200px;
        font-size: 14px;
        text-align: left;
    }

    .fade-enter-active,
    .fade-leave-active {
        transition: opacity 0.3s ease;
    }

    .fade-enter,
    .fade-leave-to {
        opacity: 0;
    }
</style>
