import { createApp } from "vue";
import { createPinia } from "pinia";
import App from "./App.vue";
import router from "./router";
// import "./assets/style.css";
import "bootstrap-icons/font/bootstrap-icons.css";
import VueTelInput from "vue-tel-input";
import "vue-tel-input/vue-tel-input.css";
import "leaflet/dist/leaflet.css";
import "./assets/styles/reset.css";

import vue3GoogleLogin from 'vue3-google-login';
import VueLazyload from 'vue3-lazyload';

const pinia = createPinia();
const globalOptions = {
  mode: "auto",
};
const addCommaSeparator = (value) =>
  value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");

const app = createApp(App);

app.use(VueLazyload, {
  lazyComponent: true,
  loading: 'https://via.placeholder.com/600x400',
  error: 'placeholder-image.jpg'
});

app.use(vue3GoogleLogin, {
  clientId: '932031693579-8ek3ouqdurk13gj2lrds7c867g8gbscv.apps.googleusercontent.com',
});

app.use(pinia);
app.use(VueTelInput, globalOptions);
app.use(router);

app.mixin({ methods: { addCommaSeparator } });
app.mount("#app");
