<template>
    <FundingPage />
    <div class="features-list">
        <div v-for="(feature, index) in currentFeatures" :key="index" class="feature-card" @click="gotoFeature(feature)">
            <div class="feature-card-image">
                <img :src="feature.image" alt="Feature Image" />
            </div>
            <div class="card-details">
                <div class="feature-card-header">
                    <h3>{{ feature.title }}</h3>
                </div>
                <div class="feature-card-content">
                    <p>{{ feature.description }}</p>
                    <ul>
                        <li v-for="(detail, i) in feature.details" :key="i"><span class="">{{ detail }}</span></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import { ref, watch, onMounted } from 'vue'
import { features, PropertyFeatures, JobFeatures, MaterialFeatures, CollaborationFeatures, FinancingFeatures, propertyManagementServices } from '@/utils/Features/Features.js';


export default {
    name: 'FeaturesPage',

    components: {

    },
    setup() {
        const route = ref(window.location.pathname);
        const currentFeatures = ref('');

        const assignCurrentFeatures = () => {
            const featureMap = {
                '/property': PropertyFeatures,
                '/jobs': JobFeatures,
                '/materials': MaterialFeatures,
                '/collaboration': CollaborationFeatures,
                '/funding': FinancingFeatures,
                '/our-services': propertyManagementServices,
                '/': features
            };

            currentFeatures.value = featureMap[route.value] || '';

            if (!currentFeatures.value) {
                console.error('No features found for the current route');
            }
        };

        const gotoFeature = (feature) => {
            window.location.href = feature.route;
        };

        watch(route, assignCurrentFeatures);

        onMounted(() => {
            assignCurrentFeatures();
        });

        return {
            features,
            PropertyFeatures,
            JobFeatures,
            MaterialFeatures,
            CollaborationFeatures,
            FinancingFeatures,
            currentFeatures,
            assignCurrentFeatures,
            gotoFeature,
        };
    }
}
</script>

<style scoped>

@import url('@/assets/styles/features.css');

</style>
