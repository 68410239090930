<template>


  <div class="hero-container" v-for="hero in filteredHeroes" :key="hero.route">
    <div class="search">
      <search-component :searchObject="items" :searchFields="searchFields" :route="route"
        @update-results="filteredResults = $event" v-if="route != '/' && route != '/our-services'" />

      <!-- Display the filtered results -->
      <div class="show-results" v-if="filteredResults.length > 0">
        <div class="results-container" v-if="route === '/jobs'">
          <div class="display-result" v-for="item in filteredResults" :key="item.id" @click="gotoDetails(item.id)">
            <h2 class="title">{{ item.title }}</h2>
            <div class="sub-titles">
              <p>Location: {{ item.location }}</p>
              <p>Job Type: {{ item.job_type }}</p>
              <p>Salary: {{ item.salary }}</p>
              <p class="description">{{ item.description }}</p>
            </div>
          </div>
        </div>
        <div class="results-container" v-else-if="route === '/materials'">
          <div class="display-result" v-for="item in filteredResults" :key="item.id" @click="gotoDetails(item.id)">
            <div class="materials-details">
              <h3>
                <strong>{{
                  new Intl.NumberFormat("en-US", {
                    style: "currency",
                    currency: "KES",
                    minimumFractionDigits: 0,
                  }).format(item.price)
                }}</strong>
              </h3>
              <p>
                <strong>{{ item.Specifications.materials.name }} --- </strong>
                <span>{{ item.Specifications.uom_types[0].value }}
                  {{
                    item.Specifications.uom_types[0].uom_mappings.abbreviation
                  }}</span>
              </p>
              <p>{{ item.Specifications.materials.description }}</p>
            </div>
            <img :src="item.Specifications.images[0].image" alt="" class="result-image" />
          </div>
        </div>
        <div class="results-container" v-else-if="route === '/property'">
          <div class="display-result" v-for="item in filteredResults" :key="item.id" @click="gotoDetails(item.id)">
            <img :src="item.property_images && item.property_images[0]
              ? item.property_images[0].image
              : ''
              " alt="" class="result-image" />
            <div class="result-details">
              <div class="result-details-info">
                <div class="basic-information">
                  <div class="property-item">
                    <img src="@/assets/svgs/bedroom-3-svgrepo-com.svg" alt="" />
                    <span>{{ item.bedrooms }}</span>
                    <strong>Beds:</strong>
                  </div>
                  <div class="property-item">
                    <img src="@/assets/svgs/bathroom-svgrepo-com.svg" alt="" />
                    <i class="bi bi-shower"></i>
                    <span>{{ item.bathrooms }}</span>
                    <strong>Baths:</strong>
                  </div>
                  <div class="property-item">
                    <img src="@/assets/svgs/house-size-svgrepo-com.svg" alt="" />
                    <i class="bi bi-arrows-fullscreen"></i>
                    <span>{{ item.square_footage }}</span>
                    <strong>{{ item.square_ft_uom }}</strong>
                  </div>
                  <div class="property-item">
                    <img src="@/assets/svgs/house-size-svgrepo-com.svg" alt="" />
                    <i class="bi bi-geo-alt"></i>
                    <span>{{ item.lot_size }}</span>
                    <strong>Ha:</strong>
                  </div>
                </div>
              </div>
              <div v-for="feature in item.property_features" :key="feature.id" class="feature">
                <strong>{{ feature.Features }}:</strong>
              </div>
            </div>
          </div>
        </div>
        <div class="results-container" v-if="route === '/transportation'">
          <div class="display-result" v-for="item in filteredResults" :key="item.id" @click="gotoDetails(item.id)">
            <h3>{{ item.city }}</h3>
            <p>{{ item.description }}</p>
            <img :src="item.property_images[0].image" alt="" class="result-image" />
          </div>
        </div>
        <div class="results-container" v-else>
          <div class="display-result" v-for="item in filteredResults" :key="item.id" @click="gotoDetails(item.id)">
            <h3>{{ item.city }}</h3>
            <p>{{ item.description }}</p>
            <img :src="item.property_images[0].image" alt="" class="result-image" />
          </div>
        </div>
      </div>
    </div>
    <div class="" v-if="filteredResults.length === 0">
      <div v-for="hero in filteredHeroes" :key="hero.route" class="hero">
        <div class="hero-text">
          <h1>{{ hero.title }}</h1>
          <h3>{{ hero.subTitle }}</h3>

          <div class="cta">
            <button @click="$router.push('/inquiry')" v-if="route === '/'">{{ hero.callToAction }}</button>
            <!-- <button @click="$router.push('/myprofile?value=list-property')" v-if="route === '/property'">Sell
                    your
                    Property
                    ....</button> -->
            <!-- <button @click="$router.push('/buy')" v-if="route === '/property'">Find your Property
                    ....</button> -->
            <button @click="$router.push('/myprofile?value=' + encodeURIComponent('funding-provider'))"
              v-if="route === '/funding'">Become a Funding Provider</button>

            <button @click="$router.push('/myprofile?value=' + encodeURIComponent('project-proposal'))"
              v-if="route === '/funding'">
              Invest in a project</button>
            <button @click="$router.push('/fundingopportunities')" v-if="route === '/funding'">
              Compare Funding Opportunities</button>
          </div>
        </div>
        <div class="hero-image">
          <img :src="hero.imageUrl" alt="Hero Image">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { ref, computed, onMounted } from "vue";
  import { heroData } from "@/utils/hero/Herocontent.js"; // Adjust the path as needed
  import SearchComponent from "@/components/SearchComponent.vue";
  import axios from "@/utils/axios";
  import { useRouter } from "vue-router";

  export default {
    components: {
      SearchComponent,
    },
    setup() {
      const route = ref(window.location.pathname);
      const items = ref([]);
      const searchFields = ref([]);
      const router = useRouter();

      // Function to generate search fields dynamically from an object

      const updateItems = async () => {
        const urlMap = {
          "/property": "/property/propertiesdetails/",
          "/funding": "/funding/financing-options/",
          "/jobs": "/jobs/jobs/",
          '/our-services': '',
          '/transportation': "/transportation/transportationdetails/",
          "/materials": "/materials/prices/",
        };
        const url = urlMap[route.value];
        if (url) {
          try {
            const response = await axios.get(url, {
              params: {
                status: true,
                completed: true,
                Specifications__completed: true,
              },
            });
            items.value = response.data;
          } catch (error) {
            console.error("Failed to fetch data:", error);
          }
        }
      };

      function generateSearchFields(item, path = "") {
        if (!item || typeof item !== "object") {
          return [];
        }

        let fields = [];
        Object.entries(item).forEach(([key, value]) => {
          // Exclude specific fields if necessary, e.g., 'id'
          if (key === "id") {
            return;
          }

          // Construct the new path based on recursion depth
          const newPath = path ? `${path}.${key}` : key;

          // Check if the value is an object or array, and recurse into it
          if (value && typeof value === "object") {
            if (Array.isArray(value)) {
              // If the value is an array, iterate over the first element if it's an object
              if (value.length > 0 && typeof value[0] === "object") {
                fields = fields.concat(generateSearchFields(value[0], newPath));
              }
            } else {
              // Recurse into the object
              fields = fields.concat(generateSearchFields(value, newPath));
            }
          } else {
            // It's a primitive value, add the path to fields
            fields.push(newPath);
          }
        });

        return fields;
      }

      // Dynamically compute search fields based on items' structure

      const filteredResults = ref([]);

      // maunually define searchfields based on route

      const searchFieldMap = {
        "/property": ["city", "address", "location", "street"],
        "/funding": ["city", "description"],
        "/jobs": ["title", "locations"],
        "/materials": ["city", "description", "name"],
      };

      const assignSearchFields = () => {
        searchFields.value = searchFieldMap[route.value] || [];
      };

      const updateResults = async (results) => {
        filteredResults.value = [results];
      };

      const filteredHeroes = computed(() => {
        return heroData.filter((hero) => hero.route === route.value);
      });

      const gotoDetails = (id) => {
        router.push(`${route.value}/${id}`);
      };

      onMounted(async () => {
        await updateItems();
        console.log("First item for search fields generation:", items.value[0]);
        setTimeout(() => {
          // Ensure this runs after any reactivity updates
          assignSearchFields(items.value);
          console.log("Assigned Search Fields:", searchFields.value);
          const testFields = generateSearchFields(items.value[0]);
          console.log("Generated Search Fields:", testFields);
        }, 1000);
      });

      return {
        filteredHeroes,
        route,
        items,
        updateItems,
        updateResults,
        searchFields,
        filteredResults,
        generateSearchFields,
        assignSearchFields,
        gotoDetails,
      };
    },
  };
</script>

<style scoped>
  /* Add your styles here */
  @import url('@/assets/styles/hero.css')
</style>
